.addLocationContainer {
    > div {
        max-width: 80%;
        margin: 0 auto;
        h3 {
            font-size: 2rem;
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 990px) {
    .addLocationContainer {
        > div {
            max-width: 100%;
        }
    }
}