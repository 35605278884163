.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  .bar {
    display: inline-block;
    width: 3px;
    height: 20px;
    background-color: var(--primaryColor);
    border-radius: 10px;
    animation: scale-up4 1s linear infinite;
    &:nth-child(2) {
      height: 35px;
      margin: 0 5px;
      animation-delay: 0.25s;
    }
    &:nth-child(3) {
      animation-delay: 0.5s;
    }
  }
}

@keyframes scale-up4 {
  20% {
    background-color: var(--primaryColor);
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
