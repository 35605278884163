/* Importing Sass Function */
@import "../../../styleFunctions/mixins";

.headerBlock {
  @include flexContainer($gap: 0, $justifyContent: space-between);
  width: 100%;
  > div {
    width: 100%;
    &:nth-of-type(1) {
      flex: 3 1;
    }
    &:nth-of-type(2) {
      @include flexContainer;
      flex: 2 1;
      > div {
        width: 100%;
        &:nth-of-type(1) {
          flex: 2 1;
        }
        &:nth-of-type(2) {
          flex: 1 1;
        }
      }
      .headerBtnContainer {
        @include flexContainer($gap: 2rem, $justifyContent: space-between);
        button {
          &:nth-of-type(1) {
            flex: 1 1 30%;
          }
          &:nth-of-type(2) {
            flex: 1 1 70%;
          }
        }
      }
      .profileContainer {
        position: relative;
        button {
          background: none;
          &:hover {
            background-color: rgb(0, 0, 0, 0.1);
            .profileMenu {
              display: block;
            }
          }
          .profileImg {
            img {
              width: 100%;
              max-width: 32px;
              aspect-ratio: 1;
              border-radius: 50%;
            }
          }
          .userName {
            p {
              margin: 0;
            }
          }
          .profileMenu {
            position: absolute;
            background: var(--whiteColor);
            box-shadow: 0 0 6px rgb(0, 0, 0, 0.1);
            width: 100%;
            max-width: 200px;
            margin: 0 auto;
            padding: 2rem;
            border-radius: 0.5rem;
            top: 45px;
            display: none;
            ul {
              margin: 0px;
              padding: 0px;
              list-style: none;
              li {
                font-size: 1.6rem;
                background-color: var(--secondaryColor);
                border-radius: 0.5rem;
                padding-block: 0.5rem
              }
            }
          } 
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .headerBlock {
    > div {
      &:nth-of-type(1) {
      flex: 1 1 40%;
      }
      &:nth-of-type(2) {
      flex: 1 1 60%;
        > div {
          flex: 1 1 !important;
        }
        .headerBtnContainer {
          button {
            flex: 1 1 !important;

            svg {
              display: none;
            }
          }
        }
      }
    }
  }
  .seeAll {
    display: none;
  }
}
