@import "../../../styleFunctions/mixins";

.sidebarContainer {
  position: fixed;
  background: var(--secondaryColor);
  width: 100%;
  height: 100%;
  max-width: 25rem;
  padding: 2rem;
  figure {
    text-align: center;
    margin-bottom: 5.6rem;
    cursor: pointer;
    img {
      max-width: 8.8rem;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include flexContainer($gap: 2rem, $alignItems: flex-start);
    flex-direction: column;
    li {
      width: 100%;
      a {
        display: block;
        color: var(--whiteColor);
        font-size: 1.6rem;
        padding: 1rem 1.5rem;
        border-radius: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        // svg {
        //   fill: var(--whiteColor);
        // }
      }
    }
    .loaderContainer {
      > div {
        > span {
          background-color: var(--whiteColor);
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .sidebarContainer {
    max-width: 20rem;
  }
}
