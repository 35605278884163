.dashboardContainer {
  display: flex;
  .dashboardContentContainer {
    width: 100%;
    max-width: calc(100% - 25rem);
    margin: 0 0 0 auto;
    flex: 1 1 auto;
    display: flex;
      flex-direction: column;
    .dashboardContent {
      margin-top: 9.2rem;
      background-color: var(--pageBgColor);
      padding-block: 3rem;
      flex: 1;
      position: relative;
      > div {
        max-width: 90%;
        margin: 0rem auto 0;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .dashboardContainer {
    .dashboardContentContainer {
      max-width: calc(100% - 20rem);
    }
  }
}
