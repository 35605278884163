@import '../../../../styleFunctions/mixins';

.popupContainer {
    @include flexContainer();
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.2);
  max-width: calc(100% - 25rem) !important;
  z-index: 99;
  .popupInnerBox {
    width: 100%;
    background-color: var(--whiteColor);
    max-width: 50rem;
    padding: 3rem;
    border-radius: 0.5rem;
    text-align: center;
    position: relative;
    > svg {
        width: 100px;
    }
    h2 {
        margin-top: 3rem;
        font-size: 2rem;
    }
    .closePopup {
        position: absolute;
        top: 5px;
        right: 5px;
        svg {
            transform: rotate(45deg);
            cursor: pointer;
        }
    }
    .errorTxt {
        color: red;
        font-size: 1.6rem;
        text-align: center;
    }
  }
}
