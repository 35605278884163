@import "../../styleFunctions/mixins";

.loginContainer {
  @include flexContainer;
  @include backgroundImage($image: url("../../assets/loginBg.png"));
  background-color: var(--secondaryColor);
  height: 100%;
  section {
    background-color: var(--whiteColor);
    width: 100%;
    max-width: 45rem;
    text-align: center;
    padding: 4rem;
    border-radius: 1rem;
    > img {
        max-width: 12rem;
        margin-block-end: 5rem;
    }
    form {
        @include flexContainer;
        flex-direction: column;
        max-width: 30rem;
        margin: 0 auto;
        label {
            font-size: 1.6rem;
            font-weight: 500;
        }
    }
  }
}
