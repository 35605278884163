@import "../../../../styleFunctions/mixins";

.blogContainer {
  .blogInnerBlock {
    @include flexContainer($gap: 4rem);
    flex-wrap: wrap;
    > div {
      flex: 1 0 300px;
      max-width: 400px;
    }
  }
}

// @media only screen and (max-width: 990px) {
//   .blogContainer {
//     .blogInnerBlock {
//       grid-template-columns: 1fr;
//     }
//   }
// }
