@import '../../../../styleFunctions/mixins';

.addLocationForm {
    @include flexContainer($gap: 4rem);
    flex-direction: column;
    width: 100%;
    > section {
        width: 100%;    
        @include flexContainer($alignItems: flex-start);
        flex-direction: column;
        > div {
            @include flexContainer;
            width: 100%;
        }
        .bannerImgInput {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 3px;
            span {
                width: 100%;
            }
        }
        .amenitiesContainer {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            > div {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 2.5rem;
            }
        }
        .storeHoursContainer {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            > input[type=checkbox]:checked ~ div {
                display: none;
            }
            > div {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 4rem;
            }
        }
    }
}

.categoryListSelector {
    width: 100%;
    background-color: var(--bgWhiteColor);
    padding: 1rem 1.5rem;
    border: 1px solid var(--bgWhiteColor);
    border-radius: 1rem;
    font-size: 1.6rem;
    &:focus {
      outline: none;
    }
}

@media only screen and (max-width: 990px) {
    .addLocationForm {
        > section {
            .amenitiesContainer {
                > div {
                    grid-template-columns: 1fr 1fr;
                }
            }
            .storeHoursContainer {
                > div {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
    }
}