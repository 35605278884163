/* Importing Sass Style Functions */
@import "../../../styleFunctions/mixins";

.locationCardContainer {
  @include flexContainer(
    $gap: 2rem,
    $justifyContent: flex-start,
    $alignItems: flex-start
  );
  flex-direction: column;
  .locationDataContainer {
    width: 100%;
    @include flexContainer(
      $gap: 4rem,
      $justifyContent: flex-start,
      $alignItems: flex-start
    );
    > div {
      @include flexContainer(
        $gap: 2rem,
        $justifyContent: flex-start,
        $alignItems: flex-start
      );
      flex-direction: column;
      &:nth-of-type(1) {
        flex: 1 1 25%;
        h2,
        h4,
        h5,
        p {
          margin: 0;
        }
      }
      &:nth-of-type(2) {
        flex: 1 1 60%;
      }
      &:nth-of-type(3) {
        flex: 1 1 15%;
      }
    }
    .locationData {
      h2 {
        font-size: 2.5rem !important;
        color: var(--headingColor);
      }
      h4 {
        font-size: 1.8rem !important;
        color: var(--greyColor);
      }
      .mapCoordinates {
        @include flexContainer($alignItems: flex-start, $gap: 0.5rem);
        flex-direction: column;
        h5 {
          font-size: 1.8rem !important;
          color: var(--greyColor);
        }
      }
    }
    .amentiesContainer {
      .amenityBlock {
        @include flexContainer($gap: 2rem, $justifyContent: flex-start);
        flex-wrap: wrap;
        // display: grid;
        // grid-template-columns: repeat(4, 1fr);
        gap: 3rem;
        // margin-top: 3rem;
      }
    }
  }
}

.storeInner {
  position: relative;
  button {
    &:hover ~ div {
      display: block;
    }
  }
  .timingData {
    position: absolute;
    background: #fff;
    top: 34px;
    width: 300px;
    box-shadow: 0 0 6px rgb(0, 0, 0, 0.2);
    border-radius: 1rem;
    padding: 1rem;
    display: none;
    > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      p {
        font-size: 1.6rem !important;
      }
    }
    > div:nth-of-type(1) {
      p {
        font-weight: 600 !important;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .locationCardContainer {
    .locationDataContainer {
      flex-direction: column;
      > div {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          flex: 1 1 100%;
        }
        &:nth-of-type(3) {
          flex-direction: row;
          width: 100%;
        }
      }
    }
  }
}
