@import "./styleFunctions/mixins"; // Importing Sass Functions
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700;800&family=Lato:wght@100;300;400;700&display=swap"); //Importing Font Family

:root {
  /* Global Colors */
  --primaryColor: #f0880e;
  --primaryGradientColor: linear-gradient(to right, #ff9269 0%, #f15b22 100%);
  --secondaryColor: #18243c;
  --greenColor: #69c200;
  --whiteColor: #fff;
  --bgWhiteColor: #f3f3f3;
  --pageBgColor: #f9f9f9;
  --headingColor: #414d5f;
  --greyColor: #999999;

  /* Global Font */
  --primaryFont: "Barlow";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--primaryFont);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

:is(h1, h2, h3, h4, h5, h6, p) {
  margin: 0;
  padding: 0;
}

:is(h1, h2, h3, h4, h5, h6, p, button, a, span, input, label, select, li) {
  font-family: var(--primaryFont);
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-weight: 600 !important;
}

p {
  font-weight: 400 !important;
}

img {
  width: 100%;
}

a {
  text-decoration: none !important;
}

/* Global Font Sizes */

h1 {
  @include fontSize(
    $minSize: 2.5rem,
    $maxSize: 3rem,
    $preferredSize: 10vw
  ); // For Main Headings
}

h2 {
  @include fontSize(
    $minSize: 2.2rem,
    $maxSize: 2.7rem,
    $preferredSize: 10vw
  ); // For Sub Headings
}

p {
  @include fontSize(
    $minSize: 1.6rem,
    $maxSize: 1.8rem,
    $preferredSize: 10vw
  ); // For Descriptive Text
}

/* Global Styles */

#root {
  @include flexContainer(
    $gap: 0px,
    $justifyContent: flex-start,
    $alignItems: flex-start
  );
  flex-direction: column;
  min-height: 100vh;
  > div {
    width: 100%;
    flex: 1 1 auto;
  }
}

header {
  @include flexContainer($justifyContent: space-between);
  height: 92px;
  padding-inline: 2rem;
  background-color: var(--pageBgColor);
  position: fixed;
  width: 100%;
  max-width: calc(100% - 25rem);
  top: 0;
  z-index: 99;
}

a.active {
  background: var(--primaryGradientColor);
}

label {
  font-size: 1.6rem;
  font-weight: 500;
}

.inputContainer,
.selectContainer {
  @include flexContainer($alignItems: flex-start, $gap: 0.5rem);
  flex-direction: column;
  width: 100%;
  input,
  select,
  textarea {
    width: 100%;
    background-color: var(--bgWhiteColor);
    padding: 1rem 1.5rem;
    border: 1px solid var(--bgWhiteColor);
    border-radius: 1rem;
    font-size: 1.6rem;
    &:focus {
      outline: none;
    }
  }
}

.textAreaContainer {
  width: 100%;
  background-color: var(--bgWhiteColor);
  padding: 1rem 1.5rem;
  border: 1px solid var(--bgWhiteColor);
  border-radius: 1rem;
  font-size: 1.6rem;
  &:focus {
    outline: none;
  }
}

button {
  @include flexContainer($gap: 1rem);
  width: 100%;
  border: none;
  padding: 1rem;
  border-radius: 0.5rem !important;
  font-size: 1.6rem !important;
  color: var(--whiteColor);
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #c3c3c3 !important;
    color: #555 !important;
  }
}

.mapMarker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  img {
    z-index: 0;
    position: relative;
    max-width: 18px;
  }
}

.mapInfoWindow {
  position: absolute;
  width: 250px;
  background: #fff;
  padding: 10px;
  border-radius: 9px;
  filter: drop-shadow(2px 4px 6px rgb(0, 0, 0, 0.3));
  z-index: 1;
  top: 0;
  transform: translate3d(-50%, calc(-100% - 15px), 0);
  left: 0;
  &::after {
    background: #fff;
    -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    content: "";
    height: 12px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 25px;
  }
  p {
    font-size: 1.6rem !important;
  }
}

.cardWrapper {
  background-color: var(--whiteColor);
  padding: 3rem;
  border-radius: 2rem;
  box-shadow: 0 0 0.8rem rgb(0, 0, 0, 0.2);
}

@media only screen and (max-width: 990px) {
  header {
    max-width: calc(100% - 20rem);
  }
}
