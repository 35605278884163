.addSubAdminContainer {
    > div {
        max-width: 80%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 990px) {
    .addSubAdminContainer {
        > div {
            max-width: 100%;
        }
    }
}