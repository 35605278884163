.tableContainer {
  table {
    width: 100%;
    table-layout: fixed;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 0 0 6px rgb(0,0,0,0.3);
    thead {
      tr {
        background-color: var(--primaryColor);
        th {
          color: var(--whiteColor);
          padding: 2rem;
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
    }
    tbody {
      position: relative;
      tr {
        border-bottom: 1px solid;
        td {
          padding: 2rem;
          h5 {
            font-size: 1.8rem;
          }
          p {
            font-size: 1.5rem !important;
          }
          span {
            display: inline-block;
            margin-right: 5px;
            font-size: 1.5rem !important;
          }
          .btnContainer {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
          }
        }
      }
      .tableLoader {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(255,255,255,0.6);
        top: 0;
        left: 0;
      }
    }
  }
}

.promoTableContainer {
  table {
    table-layout: auto;
    thead {
      tr {
        th {
          font-size: 1.6rem;
        }
      }
    }
    tbody {
      tr {
        td {
          img {
            max-width: 10rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .tableContainer {
    overflow: auto;
    table {
      table-layout: auto;
    }
  }
}
