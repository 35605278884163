@import "../../../styleFunctions/mixins";

.moduleCard {
    @include flexContainer;
    flex: 1 0 300px;
    max-width: 400px;
    width: 100%;
    min-height: 20rem;
    background-color: transparent;
    border: 2px solid var(--secondaryColor);
    border-radius: 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 0.05em;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 1.25em 2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 0.3s ease-in-out;
    user-select: none;
    font-size: 13px;
    box-shadow: 0 6px 10px rgba(207, 212, 222, 1);
    &:hover {
      color: white;
      background: var(--primaryColor);
      border-color: var(--primaryColor);
      transform: translateY(-10px);
      .topKey {
        left: -2px;
        width: 0px;
      }
      .bottomKey1,
      .bottomKey2 {
        right: 0;
        width: 0;
      }
    }
    .topKey {
      height: 2px;
      width: 1.5625rem;
      top: -2px;
      left: 0.625rem;
      position: absolute;
      background: #fff;
      transition: width 0.5s ease-out, left 0.3s ease-out;
    }
    .bottomKey1 {
      height: 2px;
      width: 1.5625rem;
      right: 1.875rem;
      bottom: -2px;
      position: absolute;
      background: #fff;
      transition: width 0.5s ease-out, right 0.3s ease-out;
    }
    .bottomKey2 {
      height: 2px;
      width: 0.625rem;
      right: 0.625rem;
      bottom: -2px;
      position: absolute;
      background: #fff;
      transition: width 0.5s ease-out, right 0.3s ease-out;
    }
    .moduleContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        h2 {
            margin: 0;
            color: var(--secondaryColor);
        }
    }
  }