/* Importing Sass Style Functions */
@import "../../../../styleFunctions/mixins";

.locationsPageContainer {
  @include flexContainer($gap: 4rem);
  flex-direction: column;
  > div {
    width: 100%;
  }
  .locationSearchSection {
    @include flexContainer;
    > div {
      width: 100%;
      h2 {
        margin-bottom: 2rem;
      }
      &:nth-of-type(2) {
        flex: 1 0 300px;
        h2 {
          text-align: center;
        }
      }
      &:nth-of-type(1) {
        flex: 1 0 300px;
      }
    }
  }
  .locationBlock {
    @include flexContainer($gap: 3rem);
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
}
