@import "../../../styleFunctions/mixins";

.promotionCard {
  flex: 1 0 360px;
  max-width: 400px;
  @include flexContainer;
  flex-direction: column;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  filter: drop-shadow(2px 4px 6px rgb(0, 0, 0, 0.2));
  > div {
    width: 100%;
  }
  .promotionImage {
    border-radius: 15px;
    margin-top: -108px;
    filter: drop-shadow(2px 4px 6px rgb(0, 0, 0, 0.2));
    position: relative;
    img {
      border-radius: 15px;
    }
  }
  .promotionContent {
    .promotionInfo {
      @include flexContainer($alignItems: flex-end);
      margin-top: 1rem;
      > div {
        width: 100%;
      }
      p {
        margin: 0;
        width: 100%;
      }
      .promotionDate {
        @include flexContainer($gap: 10px);
        flex-direction: column;
        flex: 1 0 60%;
      }
      .sequence {
        flex: 1 0 30%;
        @include flexContainer($justifyContent: flex-end);
        span {
          color: var(--whiteColor);
          background-color: var(--secondaryColor);
          width: 40px;
          aspect-ratio: 1;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.8rem;
        }
      }
    }
  }
  .btnContainer {
    width: 100%;
    display: flex;
    gap: 2rem;
  }
}
