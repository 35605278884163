.ribbon {
    position: absolute;
    right: var(--right, 10px);
    top: var(--top, -3px);
    filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.5));
}

.ribbon.down > .content {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
}

.ribbon > .content {
    color: white;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 400;
    background: var(--color, #2ca7d8) linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.25) 100%);
    padding: 8px 2px 12px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%, 0 100%);
    width: var(--width, 36px);
    min-height: var(--height, 36px);
    transition: clip-path 1s, padding 1s, background 1s;
}