@import "../../../styleFunctions/mixins";

.blogCardContainer {
  width: 100%;
  @include flexContainer(
    $gap: 2rem,
    $justifyContent: flex-start,
    $alignItems: flex-start
  );
  flex-direction: column;
  .blogImgContainer {
    background-position: center;
    background-size: cover;
    min-height: 21.7rem;
    width: 100%;
  }
  .blogContent {
    width: 100%;
    @include flexContainer(
      $gap: 2rem,
      $justifyContent: flex-start,
      $alignItems: flex-start
    );
    flex-direction: column;
    h3 {
      font-size: 2.2rem;
      margin: 0;
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      margin: 0;
    }
    .btnContainer {
      width: 100%;
      display: flex;
      gap: 2rem;
    }
  }
}
