@import "../../../../styleFunctions/mixins";

.webPromotionPage {
    @include flexContainer($gap: 150px);
    flex-direction: column;
  .webPromotionCategory {
    @include flexContainer;
    width: 100%;
    > div {
      width: 100%;
    //   text-align: center;
      button {
        background: var(--secondaryColor);
        border: none;
        padding: 10px 20px;
        display: block;
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
        border-radius: 0px !important;
        text-transform: uppercase;
        cursor: pointer;
        transform: skew(-21deg);
      }
      
      span {
        display: inline-block;
        transform: skew(21deg);
      }
      
      button::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        left: 0;
        background: var(--primaryColor);
        opacity: 0;
        z-index: -1;
        transition: all 0.5s;
      }
      
      button:hover {
        color: #fff;
      }
      
      button:hover::before {
        left: 0;
        right: 0;
        opacity: 1;
      }
      .activeButton {
        background-color: var(--primaryColor);
        pointer-events: none;
      }
    }
  }
  .promotionBlock {
    @include flexContainer($gap: 30px);
    row-gap: 130px;
    flex-wrap: wrap;
    width: 100%;
    > div {
        width: 100%;
    }
  }
}
