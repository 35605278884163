/* Importing SASS style function */
@import "./styleFunctions/mixins";

/* Single Amenity Styling */
.singleAmenity {
  @include flexContainer($gap: 0.5rem);
  flex-direction: column;
  img {
    width: auto;
  }
  p {
    color: var(--secondaryColor);
    font-size: 1.6rem !important;
    text-align: center;
  }
}

/* Popup Wrapper Styling */

.popupContainer {
  @include flexContainer($gap: 0);
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 999999999;
  .popupWrapper {
    @include flexContainer($alignItems: flex-start);
    flex-direction: column;
    background-color: var(--whiteColor);
    border-radius: 2rem;
    padding: 4rem;
    width: 100%;
    max-width: 95%;
  }
}

/* Upload Image Input Styling */

.uploadLogoContainer {
  width: 100%;
  position: relative;
  .uploadContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-inline-start: 1.5rem;
    justify-content: space-between;
    width: 100%;
    border: 0.2rem solid var(--primaryColor);
    border-radius: 0.5rem;
    font-size: 1.8rem;
    color: var(--secondaryColor);
    label {
      background-color: var(--primaryColor);
      font-weight: 600;
      font-size: 1.6rem;
      letter-spacing: 0.1rem;
      color: var(--whiteColor);
      cursor: pointer;
      padding: 10px 10px;
    }

    #choosenFile {
      margin-left: 0.3rem;
    }
  }
}

/* Checkbox Styling */

.material-checkbox {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #777777;
  cursor: pointer;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    border: 2px solid var(--primaryColor);
    border-radius: 4px;
    transition: all 0.3s;
  }
  input[type="checkbox"]:checked ~ .checkmark {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
  }
  input[type="checkbox"]:checked ~ .checkmark:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 6px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  input[type="checkbox"]:focus ~ .checkmark {
    box-shadow: 0 0 0 2px var(--primaryColor);
  }
  input[type="checkbox"] ~ .checkmark {
    border-color: var(--primaryColor);
  }
  input[type="checkbox"]:disabled ~ .checkmark {
    opacity: 0.5;
    cursor: not-allowed;
  }
  input[type="checkbox"]:disabled ~ .checkmark:hover {
    border-color: #4d4d4d;
  }
}

/* Store Hours styling */

.storeHoursBlock {
  @include flexContainer($justifyContent: flex-start);
}

/* Error Message styling */ 

.errorText {
  color: red;
  font-size: 1.2rem;
  font-weight: 600;
}

.uploadVendorFile {
  position: relative;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}